import React from 'react'

function CartFormation({data,setPopUpVisible,setitemFormation}) {
  return (
    <div className='flex justify-center'>
        <div onClick={e => {
            setPopUpVisible(true)
            setitemFormation(data)
        }} className='relative hover:shadow-xl hover:-translate-y-2 duration-200 cursor-pointer flex overflow-hidden h-72 group aspect-square bg-slate-300'>
            <img src={data.image} />
        </div>
    </div>
  )
}

export default CartFormation