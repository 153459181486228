import intro from './assets/intro.mp4'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {FaFax,FaFacebookF} from 'react-icons/fa'
import {MdLocationOn} from 'react-icons/md'
import {GiHamburgerMenu} from 'react-icons/gi'
import {TfiLinkedin} from 'react-icons/tfi'
import { Swiper, SwiperSlide } from "swiper/react";
import {CgClose} from 'react-icons/cg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination,Autoplay } from "swiper";
import { Link } from 'react-scroll'
import PopUp from './components/PopUp'
import { useState } from 'react'
import CartFormation from './components/CartFormation'

function App() {
  const [popUpVisible,setPopUpVisible] = useState(false)
  const [itemFormation,setitemFormation] = useState({})
  const [visibleMenu,setVisibleMenu] = useState(false)
  const data = [
    {
      id:1,
      image:'word.jpeg'
    },
    {
      id:2,
      image:'excel.jpeg',
    },
    {
      id:3,
      image:'office365.jpeg',
    },
    {
      id:4,
      image:'powerpoint.jpeg'
    },
    {
      id:5,
      image:'team.jpeg'
    }
  ]
  let select = {}
  return (
    <div className=" font-['satoshi']">

      <header className="flex flex-col h-screen relative after:absolute after:left-0 after:top-0 after:bg-slate-800 after:opacity-50 after:w-full after:h-full after:-z-10">
        <video src={intro} autoPlay loop muted className='w-full h-full object-cover absolute top-0 left-0 -z-10'/>
        <nav className="flex py-6 px-10 fixed w-full z-[100] bg-white items-center justify-between">
          <div><img src='logo.jpg' alt='logo' className=' w-36 sm:w-52' /></div>
          <div onClick={e => setVisibleMenu(true)} className='text-3xl text-slate-800 cursor-pointer md:hidden'><GiHamburgerMenu /></div>
          <ul className={` fixed ${ visibleMenu ? 'top-0' : 'top-[-100%]'} duration-200 left-0 w-full md:w-auto flex flex-col items-center bg-white md:flex-row md:relative md:gap-7 text-lg`}>
            <div onClick={e => setVisibleMenu(false)} className=' md:hidden cursor-pointer w-full flex justify-end p-5 text-2xl'><CgClose /></div>
            <li className=' cursor-pointer md:p-0 md:border-none p-5 border-b-2 border-blue-900 w-full md:w-auto text-center'><Link to='formation' spy={true} smooth={true} offset={-100} duration={500} >Nos formations</Link></li>
            <li className=' cursor-pointer md:p-0 md:border-none p-5 border-b-2 border-blue-900 w-full md:w-auto text-center'><Link to='apropos' spy={true} smooth={true} offset={-100} duration={500} >À propos</Link></li>
            <li className=' cursor-pointer md:p-0 md:border-none p-5 border-b-2 border-blue-900 w-full md:w-auto text-center'><Link to='contact' spy={true} smooth={true} offset={-100} duration={500} >Contacts</Link></li>
          </ul>
        </nav>
        <div className=' flex font-bold flex-col items-center justify-center h-full text-white'>
          <h1 className='text-3xl '>Bienvenue</h1>
          <p className='text-lg'>Sur le site de Value Audit et Advisory</p>
        </div>
      </header>

      <section id='formation' className=' min-h-screen flex flex-col justify-center items-center bg-slate-50'>
          
          <h2 className=' text-3xl font-semibold pb-5 italic text-center relative after:absolute after:translate-x-[-50%] after:bottom-0 after:left-[50%] after:w-20 after:h-1 after:bg-blue-900'>Nos formations</h2>
          <div className=' w-9/12 mt-10'>
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                "@1.50": {
                  slidesPerView: 3,
                  spaceBetween: 5,
                }
              }}
              modules={[Pagination,Autoplay]}
              className="mySwiper"
            >
              {
                data.map(item => {
                  return  <SwiperSlide>
                            <CartFormation data={item} setPopUpVisible={setPopUpVisible} setitemFormation={setitemFormation} />
                          </SwiperSlide>
                })
              }
              
              
            </Swiper>
          </div>
      </section>

      <section id='apropos' className='relative min-h-screen grid md:grid-cols-2'>
        <div className='absolute md:relative z-50 text-white md:text-slate-800 flex h-full gap-14 flex-col justify-center px-[6%]'>
          <h2 className=' text-3xl font-semibold italic'>À propos</h2>
          <p className=' relative pt-6  after:absolute after:top-0 after:left-0 after:w-[30%] after:h-1 after:bg-blue-900'>Value Académie est un centre de formation en ligne comptant des centaines d’étudiants connectés chaque mois à travers le monde. Quelle que soit votre objectif, Value Académie peut vous aider à l’atteindre.
              Nous offrons des formations qui répondent aux besoins du marché de l’emploi et vous accompagnons dans l’acquisition ou le renforcement de compétences clés nécessaires au développement de votre carrière professionnelle et de votre business.</p>
        </div>
        <div className=' relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-70 md:after:opacity-0'>
          <img src='apropos.jpg' alt='a props' className=' w-full h-full object-cover' />
        </div>
      </section>

      <section id='contact' className=' relative min-h-screen grid md:grid-cols-2'>
        <div className='relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-70 md:after:opacity-0'>
          <img src='contact.jpg' alt='Contacts' className=' w-full h-full object-cover' />
        </div>
        <div className=' absolute md:relative z-50 text-white md:text-slate-800 flex h-full gap-14 flex-col justify-center px-[6%]'>
          <h2 className=' text-3xl font-semibold italic'>Contacts</h2>
          <div className=' relative pt-6 after:absolute after:top-0 after:left-0 after:w-[30%] after:h-1 after:bg-blue-900'>
            <img src='logo.jpg' alt='value aa' className=' w-56 mb-10' />
            <div className=' flex items-center'>
              <span className=' p-3 mr-5 rounded-full text-xl bg-slate-600 text-white'><BsFillTelephoneFill /></span>
              <p>(+225) 01 70 02 65 12</p>
            </div>
            <div className=' flex items-center mt-5'>
              <span className=' p-3 mr-5 rounded-full text-xl bg-slate-600 text-white'><FaFax /></span>
              <p>(+225) 27 22 51 75 60</p>
            </div>
            <div className=' flex items-center mt-5'>
              <span className=' p-3 mr-5 rounded-full text-xl bg-slate-600 text-white'><MdLocationOn /></span>
              <p>Riviera Bonoumin, Immeuble ZAPO, 01 BP 5150 Abidjan 01</p>
            </div>

            <p className=' mt-5'>Suivez nous</p>
            <div className='flex mt-3 gap-5'>
              <a href='#' className=' p-3 rounded-full text-xl bg-slate-600 text-white'><FaFacebookF /></a>
              <a href='a' className=' p-3 rounded-full text-xl bg-slate-600 text-white'><TfiLinkedin /></a>
            </div>
          </div>
        </div>
      </section>

      <footer className=' flex justify-center p-7 bg-slate-600 text-white'>
        &copy; Copyright Value AA 2022
      </footer>
      <PopUp data={itemFormation} popUpVisible={popUpVisible} setPopUpVisible={setPopUpVisible} />
    </div>
  );
}

export default App;
